import React from "react";
import BusinessCard from "./Info/BusinessCard";
import "./App.css";

function App() {
    return (
        <div className="main-container">
            <main className="main-content">
                <BusinessCard/>
            </main>
        </div>
    );
}

export default App;
