import React from 'react';
import podcastIcon from './BushcraftPodcast.svg';

const BushcraftPodcastIcon = ({ size = 24 }) => (
    <img 
        src={podcastIcon}
        alt="Bushcraft Podcast"
        width={size}
        height={size}
        style={{ 
            display: 'inline-block',
            verticalAlign: 'middle'
        }}
    />
);

export default BushcraftPodcastIcon;
