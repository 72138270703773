export const APP_VERSION = 'v1.0.4';

export const ICON_SIZE = 28;

export const SOCIAL_LINKS = [
    {
        name: 'Facebook',
        icon: 'FaFacebookSquare',
        url: 'https://www.facebook.com/derbushcraftrobin',
        ariaLabel: 'Facebook',
        description: 'Folge mir auf Facebook für Bushcraft und Outdoor Content'
    },
    {
        name: 'Instagram',
        icon: 'FaInstagram',
        url: 'https://www.instagram.com/dragonito/',
        ariaLabel: 'Instagram',
        description: 'Mein persönlicher Instagram Account mit Einblicken in mein Leben'
    },
    {
        name: 'LinkedIn',
        icon: 'FaLinkedin',
        url: 'https://www.linkedin.com/in/dragonito/',
        ariaLabel: 'LinkedIn',
        description: 'Mein berufliches Profil auf LinkedIn'
    },
    {
        name: 'YouTube Guitar',
        icon: 'FaYoutube',
        url: 'https://www.youtube.com/@RobinRockGuitar',
        ariaLabel: 'YouTube Channel RobinRockGuitar',
        description: 'RobinRockGuitar: Gitarren-Tutorials und Gear Reviews'
    },
    {
        name: 'YouTube Outdoor',
        icon: 'FaYoutube',
        url: 'https://www.youtube.com/@derbushcraftrobin',
        ariaLabel: 'YouTube Channel Der Bushcraft Robin',
        description: 'Der Bushcraft Robin: Outdoor, Bushcraft und Survival'
    },
    {
        name: 'BushcraftPodcast',
        icon: 'BushcraftPodcastIcon',
        url: 'https://bushcraft-podcast.de/',
        ariaLabel: 'Bushcraft Podcast',
        description: 'Der Bushcraft Podcast: Alles über Outdoor und Survival'
    }
];
