import React, { useState } from "react";
import PropTypes from 'prop-types';
import Info from "./Info";

import {
    FaFacebookSquare,
    FaLinkedin,
    FaYoutube,
    FaInstagram
} from "react-icons/fa";
import { ICON_SIZE, SOCIAL_LINKS } from '../config/constants';
import BushcraftPodcastIcon from '../assets/icons/BushcraftPodcastIcon';

const iconComponents = {
    FaFacebookSquare,
    FaLinkedin,
    FaYoutube,
    FaInstagram,
    BushcraftPodcastIcon
};

const BusinessCard = ({ imageUrl = "robin2.jpg" }) => {
    const [hoverText, setHoverText] = useState('');

    return (
        <div className="card">
            <div className="image">
                <img alt="Robin Willig Bild" src={imageUrl}/>
            </div>
            <div className="details">
                <div className="center">
                    <h1>Robin Willig<br/><span>Software Developer</span></h1>
                    <p>Guitarplayer, Bushcrafter, Youtuber, Podcaster, Barista, Dogdad, ...</p>
                    <ul className="Social">
                        {SOCIAL_LINKS.map((link) => {
                            const IconComponent = iconComponents[link.icon];
                            return (
                                <li key={link.name}>
                                    <a 
                                        aria-label={link.ariaLabel}
                                        href={link.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onMouseEnter={() => setHoverText(link.description)}
                                        onMouseLeave={() => setHoverText('')}
                                    >
                                        <IconComponent size={ICON_SIZE}/>
                                    </a>
                                </li>
                            );
                        })}
                    </ul>
                    <div className="hover-info" style={{ 
                        opacity: hoverText ? 1 : 0,
                        transition: 'opacity 0.3s ease-in-out'
                    }}>
                        {hoverText}
                    </div>
                    <Info/>

                </div>

            </div>

        </div>
    );
}

BusinessCard.propTypes = {
    imageUrl: PropTypes.string
};

export default BusinessCard;